import { ConfluenceIcon as oldConfluenceIcon } from '@atlaskit/logo';
import { ConfluenceIcon } from '@atlaskit/temp-nav-app-icons/confluence';

import {
	DEFAULT_CLOUD_GRAPH_COMMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
} from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo, getLogoVersion, RovoLogoFromLogo } from '../../icons';
import { ConfluenceSearchResultIcon } from '../../icons/confluence-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const confluenceDCConfig: ThirdPartyConfig = {
	displayName: 'Confluence Data Center',
	id: 'confluence-dc',
	resourceType: 'confluence-dc-connector',
	icon: RovoLogoFromLogo(
		getLogoVersion(ConfluenceIcon, oldConfluenceIcon),
		'Confluence Data Center',
	),
	searchResultIcon: get3PLogo(ConfluenceSearchResultIcon, 'Confluence Data Center', 'large'),
	integrationAri: 'ari:cloud:platform::integration/confluence-dc',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Space,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Space],
			},
		],
		[
			TypeFilterValueKey.Page,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Page],
			},
		],
		[
			TypeFilterValueKey.Blogpost,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Blogpost],
			},
		],
		[
			TypeFilterValueKey.Comment,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_COMMENT_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	filters: ['lastUpdated', 'onlyTitleMatch', 'createdBy'],
	requiresOauth: true,
	isEnabled: () => true,
};
