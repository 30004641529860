import { fg } from '@atlaskit/platform-feature-flags';
import { getCreatedBy, type User, usersQuery } from '@atlassian/search-client';

import { type CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import { ProductKeys, type ProductKeys3P } from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';
import { loadRecommendedUsers } from '../utils/recommendedUser';

const MAX_SEARCHED_OPTIONS_RESULTS = 5;

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('createdBy');

const getProducts3PFromConfig = () => {
	return fg('enable_created_by_filter') ? products3PFromConfig : [];
};

const loadInitialOptions = async (config: CloudConfig) => {
	return loadRecommendedUsers(config);
};

const searchCreatedBy = async (query: string, config: CloudConfig) => {
	if (!config.intl) {
		return []; // base on existing interface, can only query with intl
	}

	const response = await getCreatedBy({
		query,
		cloudId: config.cloudId || '',
		userId: config.userId || '',
		productKey: ProductKeys.Confluence,
		maxNumberOfResults: MAX_SEARCHED_OPTIONS_RESULTS,
		intl: config.intl,
	});

	if (!response) {
		return [];
	}

	return response.map((user) => ({
		trackingKey: user.id,
		value: user.id,
		label: user.name,
		avatarUrl: user.avatarUrl,
		queryParamValue: user.id,
	}));
};

export const filter = createMultiSelectFilter({
	id: 'createdBy',
	products: [...getProducts3PFromConfig()],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'createdBy',
		fetcher: async (queryParams, _) => {
			const initialUsersResponse = await usersQuery(queryParams);

			const allCreatedBy = initialUsersResponse.data?.users ?? ([] satisfies User[]);

			const userIds = new Set();

			const createdByOptions = allCreatedBy
				.filter((user) => !userIds.has(user.accountId) && userIds.add(user.accountId)) // Remove duplicates
				.map((user) => ({
					trackingKey: user.accountId,
					value: user.accountId,
					label: user.name,
					avatarUrl: user.picture,
					queryParamValue: user.accountId,
				}));
			return createdByOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return loadInitialOptions(config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			return searchCreatedBy(query, config);
		},
	},
});
